













































































































































































































































































import Vue from "vue";
import { mapState } from "vuex";
import { download } from "@/utils";
import WOW from "wow.js";
import "swiper/swiper-bundle.min.css";

export default Vue.extend({
  data() {
    return {
      scrollTop: 0,
      swiperOptions: {
        speed: 600,
        shortSwipes: false,
        longSwipesMs: 50,
        longSwipesRatio: 0.05
      }
    };
  },
  computed: {
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      oss: "oss",
      url: "url"
    }),
    intro(): string {
      if (!this.isMobile) {
        return String(this.$t("cyberboardR2.intro"))
          .replace("<br />", "")
          .replace("<br />", "");
      } else {
        return String(this.$t("cyberboardR2.intro"));
      }
    }
  },
  methods: {
    title(title: string): string {
      if (this.isCN && !this.isMobile) {
        return title.replace("<br />", "");
      } else {
        return title;
      }
    },
    onDownload() {
      download(this.oss.pdf_cyberboard_r2_r3);
    },
    onVerify() {
      window.open(this.$paths.verify + "cyberboard/", "_blank");
    }
  },
  mounted() {
    setTimeout(() => {
      new WOW({ animateClass: "animate__animated", offset: 100 }).init();
    }, 1000);
  }
});
